<template>
  <v-container fluid align-start class="terms">
    <h1 class="c32-20-dm-sans">{{pageTitle}}</h1>
    <div class="text" v-html="pageContent"></div>
  </v-container>
</template>

<script>
import Http from "../common/js/Http.js";

export default {
  name: 'Termeni',
  data() {
    return {
      pageTitle: '',
      pageContent: ''
    }
  },
  watch: {
  },
  methods: {
    async getTerms() {
      let http = new Http();
      let terms = await http.get(`/webapi/default/terms`);
      this.pageTitle = terms.data.data.title;
      this.pageContent = terms.data.data.content;
    }
  },
  async created() {
    await this.getTerms()
  },
  components: {
  }
}
</script>
<style scoped>
.terms h1 {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;
}
</style>
